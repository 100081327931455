import React, { useEffect, useState } from 'react';
import TransactionViewModeStyles from './TransactionViewModeStyles';
import { TransactionFieldConfigStyles as AiTransactionFieldConfigStyles } from './ai/TransactionFieldConfig';
import { TransactionFieldConfigStyles as DrcTransactionFieldConfigStyles } from './cd/TransactionFieldConfig';
import { TransactionFieldConfigStyles as GyTransactionFieldConfigStyles } from './gy/TransactionFieldConfig';
import type { Transaction } from '../../modules/Transactions';
import { useActiveProcedure } from '../../modules/Procedures';
import { isCountry } from '../../modules/User/utils';
import useOnlineStatus from '../../hooks/useOnlineStatus';

interface Props {
  transaction: Transaction;
  children: React.ReactNode;
}
const Fieldset: React.FC<Props> = ({ transaction, children }) => {
  const [disabled, setDisabled] = useState(false);
  const { procedure } = useActiveProcedure({ transactionLocalId: transaction.localId });
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (
      procedure?.activeStatus === undefined ||
      procedure?.activeStatus === 'Draft' ||
      (procedure?.activeStatus === undefined && !isOnline)
    ) {
      setDisabled(false);
      return;
    }

    if (procedure?.activeStatus !== 'Draft') {
      setDisabled(true);
    }
  }, [isOnline, procedure, setDisabled]);

  return (
    <fieldset style={{ border: 0, margin: 0, padding: 0 }}>
      {/* @ts-ignore */}
      {<TransactionViewModeStyles disabled={disabled} />}
      {/* @ts-ignore */}
      {isCountry('AI') && <AiTransactionFieldConfigStyles />}
      {isCountry('CD') && <DrcTransactionFieldConfigStyles />}
      {isCountry('GY') && <GyTransactionFieldConfigStyles />}
      {children}
    </fieldset>
  );
};

export default Fieldset;
